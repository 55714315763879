import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "agreementDurationUnitInput",
    "agreementDurationValueInput",
    "autoRenewalSettingsFieldset",
    "agreementExpirationRemindersEnabledInput",
    "billingTypeInput",
    "chargingEnabledInput",
    "chargingPricesFieldset",
    "daysToAgreementExpirationBeforeReminderInput",
    "defaultNoticePeriodInput",
    "eligibilityCodeLabelInput",
    "eligibilityCodeRequiredInput",
    "priceIndexerEnabledInput",
    "priceIndexerSettingsFieldset",
    "pricePerPermitFixedInput",
    "pricePerPermitInput"
  ];

  connect() {
    let inputs = [
      this.agreementExpirationRemindersEnabledInputTarget,
      this.eligibilityCodeRequiredInputTarget,
      this.priceIndexerEnabledInputTarget,
      this.billingTypeInputTargets.find(option => option.checked)
    ];

    if (this.hasChargingEnabledInputTarget) {
      inputs.push(this.chargingEnabledInputTarget);
    }

    inputs.forEach(i => i.dispatchEvent(new Event("input")));
  }

  toggleEligibilityCodeLabelInput(event) {
    this.eligibilityCodeLabelInputTarget.disabled =
      !event.currentTarget.checked;
  }

  toggleAutoRenewalSettings(event) {
    this.autoRenewalSettingsFieldsetTarget.disabled =
      event.currentTarget.value != "one_off";
  }

  togglePriceIndexingSettings(event) {
    this.priceIndexerSettingsFieldsetTarget.disabled =
      !event.currentTarget.checked;
  }

  toggleChargingPrices(event) {
    this.chargingPricesFieldsetTarget.disabled = !event.currentTarget.checked;
  }

  toggleDaysToAgreementExpirationBeforeReminderInput(event) {
    this.daysToAgreementExpirationBeforeReminderInputTarget.disabled =
      !event.currentTarget.checked;
  }

  togglePriceIndexerEnabledInput(event) {
    let value = event.currentTarget.value;
    this.priceIndexerEnabledInputTarget.disabled = value == "one_off";

    this.priceIndexerSettingsFieldsetTarget.disabled = (
      value == "one_off" || !this.priceIndexerEnabledInputTarget.checked
    );
  }

  toggleDefaultNoticePeriodInput(event) {
    this.defaultNoticePeriodInputTargets.forEach(i => {
      i.disabled = event.currentTarget.value == "one_off";
    });
  }

  toggleAgreementDurationUnitInput(event) {
    this.agreementDurationUnitInputTargets.forEach(i => {
      i.disabled = event.currentTarget.value != "one_off";
    });
  }

  toggleAgreementDurationValueInput(event) {
    this.agreementDurationValueInputTarget.readOnly = (
      event.currentTarget.value != "one_off"
    );
  }

  togglePricePerPermitFixedInput(event) {
    this.pricePerPermitFixedInputTarget.readOnly = (
      event.currentTarget.value != "one_off"
    );
  }

  togglePricePerPermitInput(event) {
    this.pricePerPermitInputTarget.readOnly = (
      event.currentTarget.value == "one_off"
    );
  }
}
